import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/form-contact"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Contacter Géotechnique Maison.fr</title>
        <meta name="description" content="Contact et demande de renseignement au sujet de l’étude de sol | Géotechnique Maison" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Contacter Géotechnique Maison.fr
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Nous vous répondrons dans les plus brefs délais.
              <br />
              Si vous êtes à la recherche d’une étude de sol pour votre projet et que vous souhaitez obtenir un devis personnalisé, <a className="underline text-emerald-500" href="/etude-sol/devis/">c’est ici que cela se passe</a>.
              <br />
              Pour tous autres renseignements ou demandes, vous pouvez nous contacter par l’intermédiaire du formulaire ci-dessous.
              </p>
            </div>

          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}